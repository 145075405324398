import "../App.css";
import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Facebook from "../asstes/facebooklogin.png";
import Google from "../asstes/google.png";
import Cookies from "js-cookie";
import { apiHost } from "../Helper";
import { AppContext } from "../Context";
import SignalRService from "../SignalR";
import moment from "moment";
import { ConsoleLogger } from "@microsoft/signalr/dist/esm/Utils";
// import info from "../asstes/info.svg";
// import closeIcon from "../asstes/closeIcon.svg";

function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passworderr, setPassworderror] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [type, settype] = useState("password");
  // const [isHovered, setIsHovered] = useState(false);
  const [isHovered, setIsHovered] = useState(false); // State to toggle between divs
  const stepContainerRef = useRef(null); // Reference to detect clicks outside
  const [isBlackListUser, setIsBlackListUser] = useState(false);

  // Handle hover or tap event to show the steps
  const handleHoverOrTap = () => {
    setIsHovered(true);
  };

  // Function to handle clicks outside the component
  const handleClickOutside = (event) => {
    if (
      stepContainerRef.current &&
      !stepContainerRef.current.contains(event.target)
    ) {
      setIsHovered(false); // Revert to the circle div if clicked outside
    }
  };

  // Add and remove event listener for clicks outside
  useEffect(() => {
    if (isHovered) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup
    };
  }, [isHovered]);
  // const [showContent, setShowContent] = useState(false);
  const navigate = useNavigate();

  const context = useContext(AppContext);

  useEffect(() => {
    // if (sessionStorage.getItem("previousPathName") == "/Ordercom") {
    //   sessionStorage.removeItem("sessionIn");
    // }
    // localStorage.setItem("MyOrders", "True");
    if (window.location.host == "localhost:3000") {
      setEmail("usamayasir32@gmail.com");
      setPassword("Admin@123");
    } else if (localStorage.Testing == "True") {
      setEmail("daniyalqazi94@gmail.com");
      setPassword("Admin@123");
    }
    context.dispatch({ Type: "NAVIGATION" });
  }, [null]);

  const changetype = async () => {
    settype("input");
  };
  const changetypepass = async () => {
    settype("password");
  };

  const getlocation = () => {
    return new Promise((resolve, reject) => {
      // console.log(navigator.permissions);
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          // console.log(result.state);
          if (result.state == "granted" || result.state == "prompt") {
            try {
              navigator.geolocation.getCurrentPosition(
                function (position) {
                  fetch(
                    `https://api.geoapify.com/v1/geocode/reverse?lat=${position.coords.latitude}&lon=${position.coords.longitude}&format=json&apiKey=21f61a865243443b8b07654137eadc62`,
                    { method: "GET" }
                  )
                    .then((res) => res.json())
                    .then((data) => {
                      // console.log(data);
                      let newObj = { ...data.results[0] };
                      fetch(
                        `${apiHost}/api/Orders/GetCoordinates?city=${newObj.city}&state=${newObj.state}&country=${newObj.country}`,
                        {
                          method: "GET",
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                              "authToken"
                            )}`,
                          },
                        }
                      )
                        .then((res) => res.json())
                        .then((ress) => { });
                      resolve(newObj);
                    })
                    .catch((err) => {
                      console.error(err);
                      resolve({
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                      });
                    });
                },
                (err) => {
                  console.error(err);
                  resolve(null);
                }
              );
            } catch (err) {
              console.error(err);
            }
          } else {
            resolve(null);
          }
        });
    });
  };

  const getLastLogin = async (id) => {
    let url = `${apiHost}/api/Authentication/LastLogin?ID=${id}`;
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching last login:", error);
      return null;
    }
  };

  const login = async () => {
    setIsLoading(true);
    let is1FX = props.is1FX;
    let coordinates = null;
    try {
      let result = await getlocation();
      // console.log(result);
      coordinates = result != null && JSON.stringify(result);
      // console.log("The Coordinates are : ", coordinates);
      // console.log("The value of is1FX is : ", is1FX);
      let Email = email.toLocaleLowerCase();
      let url = `${apiHost}/api/Authentication/Login?email=${Email}&password=${password}&location=${coordinates}&pathname=${is1FX ? "1Fx" : "FullFx"
        }`;
      axios
        .post(url)
        .then(async (response) => {
          if (response.data == "") {
            setPassworderror("Email or Password is incorrect!");
            setIsLoading(false);
          } else if (response.data.token == "" || response.data.token == null) {
            setPassworderror(
              "Your account has been restricted for about 30 minutes."
            );
            setIsLoading(false);
          } else if (response.data.isBlackListed == true) {
            setPassworderror(
              "Your account has been suspended due to suspicious activity. If you believe this is a mistake, please contact our support team at "
            );
            setIsBlackListUser(true);
            setIsLoading(false);
          }
          else {
            let lastChecked = await getLastLogin(response.data.id)
            //  console.log("lasttkka",chek)
            //   let lastChecked = new Date(response.data.welcomeTime).getMonth();
            //let lastChecked = 0;
            // console.log("The value of is1FX is : ", is1FX);
            let current = new Date().getMonth();
            Cookies.set("email", Email);
            const cookieWarning = localStorage.Cookie === "SEEN";
            localStorage.clear();
            if (cookieWarning) {
              localStorage.setItem("Cookie", "SEEN");
            }
            sessionStorage.setItem("logedin", true);
            sessionStorage.setItem("email", Email);
            localStorage.setItem("authToken", response.data.token);
            sessionStorage.setItem("sessionIn", 1);
            sessionStorage.setItem("publicKey", response.data.publicKey);
            sessionStorage.setItem("privateKey", response.data.privateKey);
            sessionStorage.setItem("isMute", response.data.isMute);
            sessionStorage.setItem(
              "Reviewedusers",
              response.data.reviewedusers
            );
            sessionStorage.setItem("UserLoginTime", moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"))
            sessionStorage.setItem("UserName", response.data.userName);
            sessionStorage.setItem("City", response.data.city);
            sessionStorage.setItem("Province", response.data.province);
            sessionStorage.setItem("Country", response.data.country);
            sessionStorage.setItem("contact", response.data.mobileNumber);
            sessionStorage.setItem("UserID", response.data.id);
            sessionStorage.setItem("LastMsg", response.data.lastMsg);
            sessionStorage.setItem("LastInvite", response.data.lastInvite);
            sessionStorage.setItem("Picture", response.data.picture);
            sessionStorage.setItem("IsMC", response.data.isMC);
            sessionStorage.setItem("IsMCPrime", response.data.isMCPrime);
            sessionStorage.setItem("settingProfilePic", [
              response.data.picture + "," + response.data.userName,
            ]);
            sessionStorage.setItem("IsMCPrimeAuthenticated", false);
            sessionStorage.setItem("loginStamp", Date.now());
            localStorage.setItem("FinalizedData", "DefaultOrders");
            localStorage.setItem("matchesOrder", "DefaulMatches");
            sessionStorage.setItem(
              "countrywhereGovernmentIdissued",
              response.data.countrywhereGovernmentIdissued
            );
            if (
              response.data.mobileNumber == undefined ||
              response.data.mobileNumber == ""
            ) {
              // console.log("IF Condition to check whether it is isFX or not");
              is1FX = true;
            }
            sessionStorage.setItem(
              "TimeZone",
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
            setIsLoading(false);
            SignalRService.registerConnection(sessionStorage.UserID);
            context.dispatch({
              Type: "FULLFXCHECK",
              Payload: response.data.city == null || "" ? false : true,
            });
            context.dispatch({ Type: "NAVIGATION" });
            context.dispatch({
              Type: "PERMISSION",
              Payload: {
                isMc: response.data.isMC,
                isMcprime: response.data.isMCPrime,
                isMcprimeAuthenticated: response.data.isMCPrimeAuthenticated,
              },
            });

            if (is1FX) {
              // console.log(lastChecked)
              // console.log(lastChecked - current, "lastChecked - current");
              console.log("1-FX Login");
              if (lastChecked === true) {
                console.log("one month ago")
                sessionStorage.setItem("prev", "1fx");
                navigate(`/Welcome?ID=${response.data.id}&t=${Date.now()}`);
              } else {
                // navigate(`/OrderFXHome?ID=${response.data.id}&t=${Date.now()}`);
                console.log("recent")
                // window.location.href =
                //   window.location.origin +
                //   `/OrderFXHome?ID=${response.data.id}&t=${Date.now()}`;
                navigate(`/OrderFXHome?ID=${response.data.id}&t=${Date.now()}`)
              }
            } else {
              console.log(lastChecked)
              context.dispatch({
                Type: "NavMobile",
                Payload: { tab: "My Orders" },
              });
              if (lastChecked === true) {
                console.log("LastChecked Block");
                console.log("one month ago")
                sessionStorage.setItem("prev", "fullfx");
                navigate(`/Welcome?ID=${response.data.id}&t=${Date.now()}`);
              } else {
                console.log("Else Block");
                console.log("recent")
                navigate(`/OrderCom?ID=${response.data.id}&t=${Date.now()}`);
              }
            }

            // isAlreadyVerified(response.data.id);
            console.log("Before the Fetch Block");

            fetch(
              `https://api.opencagedata.com/geocode/v1/json?q=${response.data.city}&key=ede4e8b4a1fb486f9f30e23b53b2504d&pretty=1`,
              {
                method: "GET",
              }
            )
              .then((res) => res.json())
              .then((ress) => {
                let giveLat = ress.results[0].annotations.DMS.lat;
                let giveLng = ress.results[0].annotations.DMS.lng;
                giveLat =
                  parseFloat(giveLat.split("°")[0]) +
                  parseFloat(giveLat.split("°")[1].split("'")[0]) / 60 +
                  parseFloat(giveLat.split("°")[1].split("'")[1]) / 3600;
                giveLng =
                  parseFloat(giveLng.split("°")[0]) +
                  parseFloat(giveLng.split("°")[1].split("'")[0]) / 60 +
                  parseFloat(giveLng.split("°")[1].split("'")[1]) / 3600;
                var giveCityLatLon = { lat: giveLat, lon: giveLng };
                // console.log("The Location is : ", giveCityLatLon);
                sessionStorage.setItem(
                  "UserCoordinates",
                  JSON.stringify(giveCityLatLon)
                );
              });
          }
        })
        .catch((error) => {
          //console.log(error);
          setPassworderror("Email or Password is incorrect!");
          setIsLoading(false);
        });
    } catch (err) {
      console.err(err);
    }
  };

  const loginOnEnter = (e) => {
    if (e.charCode == 13) {
      login();
    }
  };

  return (
    <div>
      <Container>
        <Row className="centeritem ">
          <Col md={props.is1FX || props.isFullFx ? 8 : 5} className="formbg ">
            <Form className="forminput radius contentcenter text-center">
              <Form.Group
                className="mb-3 text-center"
                controlId="formBasicEmail"
              >
                <p className="text-form  mb-3">
                  Why go to banks when you can exchange with other people
                </p>

                <Form.Control
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onKeyPress={loginOnEnter}
                  value={email}
                  type="email"
                  placeholder="Enter email"
                  onClick={(e) => {
                    setPassworderror(null);
                  }}
                />
              </Form.Group>

              <InputGroup className="mb-3">
                <Form.Control
                  id="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyPress={loginOnEnter}
                  type={type === "password" ? "password" : "input"}
                  placeholder="Password"
                  aria-label="Password"
                  aria-describedby="basic-addon2"
                  value={password}
                />
                {type === "password" ? (
                  <div style={{ zIndex: 10 }} className="eye-icon">
                    <i onClick={changetype} className="fa fa-eye-slash"></i>
                  </div>
                ) : (
                  <div className="eye-icon" style={{ zIndex: 10 }}>
                    <i onClick={changetypepass} className="fa fa-eye"></i>
                  </div>
                )}
              </InputGroup>
              {/* support@mobala.ca or visit our help center. */}
              <p style={{ marginBottom: 14, color: "red" }}>{passworderr}{isBlackListUser && <span><a href="#">support@mobala.ca</a> or visit our help center.</span>}</p>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                  variant="primary"
                  className="btnlogin"
                  style={props.is1FX || props.isFullFx ? {} : { width: "100%" }}
                  // type="submit"
                  onClick={login}
                >
                  {isLoading ? (
                    <>
                      {/* <span className="">Loading</span> */}
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                      ></Spinner>
                    </>
                  ) : (
                    "Login"
                  )}
                </Button>
              </div>
              <Row className="mt-3 text-center">
                <a
                  onClick={() => navigate("/passreset")}
                  className="fpass"
                  style={{ textDecoration: "underline" }}
                >
                  Forgot password?
                </a>
              </Row>
              {props.is1FX ? null : (
                <>
                  <p className="text-form  mt-3 text-center">
                    Don't have an account?{" "}
                    <a
                      onClick={() => navigate("/SignUp")}
                      style={{
                        color: "#0d6efd",
                        textDecoration: "underline",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Create one here
                    </a>
                  </p>
                </>
              )}
            </Form>
          </Col>
        </Row>
        {/* <div className="step-container">
      <div className="step">
        <div className="circle">1</div>
        <p>Step One</p>
      </div>

      <div className="line"></div>

      <div className="step">
        <div className="circle">2</div>
        <p>Step Two</p>
      </div>

      <div className="line"></div>

      <div className="step">
        <div className="circle">3</div>
        <p>Step Three</p>
      </div>
    </div> */}

        {/* <div
        className="d-flex"
        style={{
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          overflow: "hidden",
          margin: "0 auto",
          marginBottom: "50px",
          cursor: "pointer", // to indicate it's clickable
        }}
        
      >
        <div
          style={{
            background: "#a6a6a6",
            color: "#a6a6a6",
            width: "50%",
            height: "100%", // Make sure it fills the entire height
          }}
        >
          .
        </div>
        <div
          style={{
            background: "#4f81bd",
            color: "#4f81bd",
            width: "50%",
            height: "100%", // Make sure it fills the entire height
          }}
        >
          .
        </div>
      </div> */}

        <div style={{ marginTop: "70px" }}>
          {!isHovered ? (
            <div
              className="d-flex"
              style={{
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                overflow: "hidden",
                margin: "0 auto",
                marginBottom: "50px",
                cursor: "pointer",
                transition: "transform 0.5s ease-in-out", // Transition for smooth scaling
                transform: isHovered ? "scale(1.5)" : "scale(1)", // Scale on hover
              }}
              onMouseEnter={handleHoverOrTap}
              onTouchStart={handleHoverOrTap}
            >
              <div
                style={{
                  background: "#a6a6a6",
                  width: "50%",
                  height: "100%",
                  transition: "background 0.3s ease", // Smooth background color transition
                }}
              ></div>
              <div
                style={{
                  background: "#4f81bd",
                  width: "50%",
                  height: "100%",
                  transition: "background 0.3s ease", // Smooth background color transition
                }}
              ></div>
            </div>
          ) : (
            <div
              className="step-container"
              ref={stepContainerRef} // Reference to detect clicks outside
              onMouseLeave={() => setIsHovered(false)} // Revert when mouse leaves
              style={{
                margin: "0 auto",
                marginBottom: "15px",
                transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
                opacity: isHovered ? 1 : 0,
                transform: isHovered ? "translateY(0)" : "translateY(-10px)",
              }}
            >
              {/* Step 1 */}
              <div className="step">
                <div className="circle">1</div>
                <p style={{ fontSize: "12px" }}>Order</p>
              </div>

              {/* Line between Step 1 and Step 2 */}
              <div className="line"></div>

              {/* Step 2 */}
              <div className="step">
                <div className="circle">2</div>
                <p style={{ fontSize: "12px" }}>Match</p>
              </div>

              {/* Line between Step 2 and Step 3 */}
              <div className="line"></div>

              {/* Step 3 */}
              <div className="step">
                <div className="circle">3</div>
                <p style={{ fontSize: "12px" }}>Finalize</p>
              </div>
            </div>
          )}
        </div>
        {/* Conditionally render the paragraph when hovered or clicked */}

        <p style={{ textAlign: "justify", fontSize: "12px" }}>
          While we make utmost efforts to protect the identity and privacy of
          the users, in these times of advanced technologies, numerous
          organizations have fallen victim to fraud and deception. By using
          Mobala, you agree to not hold us liable for any losses.
        </p>
      </Container>
      {/* {session.length > 0 ? session.map((d, i) => <p>{d.date_time}</p>) : null} */}
    </div>
  );
}

export default Login;
